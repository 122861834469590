import { NETWORK_NAME } from './constants';
import { debug, error, generateXID } from './utils';
import {
  buildTrackingPayload,
  initHRST,
  getAffiliateNetwork,
  affiliateTrackingEnabled,
} from '../utils';

const HRST = initHRST();
const XID_URL_PLACEHOLDERS = ['{xid}', encodeURI('{xid}')];
const SUBID_URL_PLACEHOLDERS = ['{subid}', encodeURI('{subid}')];

/** Replaces xid placeholder with the real value in link attributes */
export const addXidToLink = (link, xid, affiliateUrl) => {
  // TODO: What about 'data-href' in legacyFre, 'data-product-url', 'data-affiliate-url', ...
  // NOTE: data-href should be the trackonoic's product_metadata.link value

  const cipd2 = HRST?.commerce?.affiliate?.trackonomics?.cipd2 ?? 'TEMPLATEERROR';

  ['href', 'data-affiliate-url'].forEach((attrName) => {
    // WIP: affiliateUrl is obtained from the callee
    let newAttr = affiliateUrl;
    if (newAttr === undefined) {
      error(`Could not find ${attrName} attribute in the node`, link);
      return;
    }

    if (newAttr != null) {
      XID_URL_PLACEHOLDERS.forEach((t) => {
        newAttr = newAttr.replace(t, xid);
      });

      SUBID_URL_PLACEHOLDERS.forEach((t) => {
        newAttr = newAttr.replace(t, cipd2);
      });

      link.setAttribute(attrName, newAttr);
    }
  });
};

/** Returns query params as object */
const getQueryParams = (link) => {
  try {
    const url = new URL(link);
    return [...url.searchParams.entries()].reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  } catch (e) {
    return {};
  }
};

/**
 * Determines if element contains the data-affiliate-network
 * attribute and if that attribute's data network.name is Trackonomics
 */
export const isTrackonomics = (element) => {
  const foundTrackonomics = getAffiliateNetwork(element)?.network?.name === NETWORK_NAME;
  /*
  if (foundTrackonomics) {
    log('isTrackononics', foundTrackonomics, element);
  }
  */
  return foundTrackonomics;
};

/* eslint-disable max-len */

/** Returns links which belongs to trackonomics network
 *
 * Example:
 * <a
 *   class="product-btn-link"
 *   href="https://cuup.pxf.io/c/3006986/962066/12811?u=https%3A%2F%2Fshopcuup.com%2Fproducts%2Fthe-plunge-mesh-leopard%3Fvariant%3D16586568138818&amp;subId3=xid:%7Bxid%7D"
 *   data-href="https://cuup.pxf.io/c/3006986/962066/12811?u=https%3A%2F%2Fshopcuup.com%2Fproducts%2Fthe-plunge-mesh-leopard%3Fvariant%3D16586568138818&amp;subId3=xid:{xid}"
 *   data-product-url="https://shopcuup.com/products/the-plunge-mesh-leopard?variant=16586568138818"
 *   data-affiliate-url="https://cuup.pxf.io/c/3006986/962066/12811?u=https%3A%2F%2Fshopcuup.com%2Fproducts%2Fthe-plunge-mesh-leopard%3Fvariant%3D16586568138818&amp;subId3=xid:{xid}"
 *   data-vars-ga-call-to-action="$68 AT SHOPCUUP.COM" data-vars-ga-link-treatment="(not set) | (not set)"
 *   data-vars-ga-media-role="1"
 *   data-vars-ga-outbound-link="https://shopcuup.com/products/the-plunge-mesh-leopard?variant=16586568138818"
 *   data-vars-ga-product-brand="Cuup"
 *   data-affiliate-network="{&quot;id&quot;:&quot;e21af561-8f5e-4b54-ad7d-e6bee7dc215f&quot;,&quot;affiliate_network_id&quot;:&quot;adb4839d-0e5c-4ae3-9f1e-8d9142c28262&quot;,&quot;retailer_id&quot;:&quot;48d6e1f7-a42a-4889-b34a-8449c2b72bea&quot;,&quot;site_id&quot;:&quot;81bbdae2-81fb-4d95-b643-a0679795a2a4&quot;,&quot;is_active&quot;:true,&quot;details&quot;:&quot;&quot;,&quot;metadata&quot;:{&quot;trackonomics&quot;:{&quot;merchant_id&quot;:&quot;360&quot;,&quot;merchant_name&quot;:&quot;Cuup&quot;,&quot;network_name&quot;:&quot;IR&quot;}},&quot;created_at&quot;:&quot;2021-10-04T16:00:17.101954+00:00&quot;,&quot;last_updated_at&quot;:&quot;2021-10-08T10:00:13.963600+00:00&quot;,&quot;network&quot;:{&quot;id&quot;:&quot;adb4839d-0e5c-4ae3-9f1e-8d9142c28262&quot;,&quot;name&quot;:&quot;Trackonomics&quot;,&quot;is_active&quot;:true,&quot;business_unit_id&quot;:&quot;ad046b46-538b-42cb-aa54-c3d158875ed6&quot;,&quot;details&quot;:&quot;&quot;,&quot;metadata&quot;:[],&quot;created_at&quot;:&quot;2021-10-02T23:45:10.234682+00:00&quot;,&quot;last_updated_at&quot;:&quot;2021-10-02T23:45:10.234694+00:00&quot;}}"
 *   data-vars-ga-product-id="b7bf4d12-7691-4f09-82aa-8739533b42a8"
 *   data-vars-ga-product-price="$68.00"
 *   data-vars-ga-product-retailer-id="106d33c7-f577-474d-a6a0-cd9386574da0"
 *   data-vars-ga-product-sem3-brand=""
 *   data-vars-ga-product-sem3-category=""
 *   data-vars-ga-product-sem3-id=""
 *   target="_blank" role="button" rel="nofollow"
 *   aria-label="$68 AT SHOPCUUP.COM for The Plunge Bra" title="$68 AT SHOPCUUP.COM">
 *  <span class="product-btn-link-text">$68 AT SHOPCUUP.COM</span>
 * </a>
 *
 */
/* eslint-enable max-len */
export const findLinks = (nodes) => {
  // Ignore links with afflinks href; they will be handled by afflinks
  const querySelectorStatement = 'a[data-affiliate-network]:not([href*="_p/afflink/"])';
  debug('searching links by', querySelectorStatement);

  const links = nodes.reduce((acc, n) => (n.querySelectorAll ? [
    ...acc,
    ...n.querySelectorAll(querySelectorStatement),
  ] : acc), []);

  return [...(new Set(links.filter(isTrackonomics)))];
};

/** Generate new xid and set it into element if it not set before
 * @param {HTMLAnchorElement} element
 * @returns
 */
export const getSetXid = (element) => {
  if (!affiliateTrackingEnabled()) {
    element.setAttribute('data-trackonomics-xid', 'gdpr-declined');
  }
  let xid = element.getAttribute('data-trackonomics-xid');
  if (!xid) {
    xid = generateXID();
    element.setAttribute('data-trackonomics-xid', xid);
  }
  return xid;
};

/**
 * @returns {Object} Returns link data:
 *  {
 *     env: string, // Environment name
 *     id: string, // Unique identifier of the click 'fr{timestamp}{a-z}{a-z}{a-z}'
 *     app_key: string, // Specific key for this app
 *     property_id: 'PROPERTY_ID', // Who is the initiator of the call.
 *                                    This is a fixed value, and should not be changed
 *     customer_id: string, // The customer environment code + script profile (cidp1_cidp2)
 *     cidp1: string, // The customer environment code
 *     cidp2: string, // Script profile name
 *     event_type: 'click',
 *     network: string, // Affiliate network short name according to the trackonomics map.
 *     data: { // Payload of the click. URL Encoded JSON
 *       attr_forwarding: { // Query string parameters. Metadata that can be passed to
 *                             track on click.
 *         utm_source: 'google',
 *       },
 *       metadata: { // Fully customizable parameters for tracking clicks and sales
 *         pageViewGuid: 'ac7308c0-7104-11eb-915a-25e6f903d7ef',
 *         articleId: '5f704ddb-98bc-448f-8e0c-a5cecdfaac69',
 *         otherparam: '12345',
 *       },
 *       property_id: 'PROPERTY_ID', // Who is the initiator of the call
 *       customer_id: string, // The property id that exist in the interface
 *       cidp1: string, // The customer environment code
 *       cidp2: string, // Script profile name
 *       source: string, // The Article URL the page converted from
 *       event_type: 'click',
 *       referrer: string, // The page from which the content came from.
 *       uuid: xid, // Should be the same as the ID
 *       link_replaced: false,
 *       client_time: '2021-02-17T09:34:23.899Z', // JS Time
 *       anchor_id: '',
 *       anchor_href: string, // The link with the appended XID
 *       anchor_orig: string, // The original link before XID was appended
 *       anchor_label: string, // The label text inside the anchor
 *       page_title: string, // The article title in page
 *       spot_id: '',
 *       network: string, // The href detected affiliate network in trackonomics format
 *                           If not available needs to default to other
 *     },
 *   }
 */
export const getLinkData = (link) => {
  // Example: data-affiliate-network = {
  //     "id": "4d1a6fe3-bec5-4903-9dbf-2a27fb4f9bee",
  //     "site_id": "81bbdae2-81fb-4d95-b643-a0679795a2a4",
  //     "is_active": true,
  //     "details": "",
  //     "metadata": {
  //         "trackonomics": {
  //             "merchant_id": "360",
  //             "merchant_name": "Cuup",
  //             "network_name": "IR"
  //         }
  //     },
  //     "network": {
  //         "id": "fbb9b02d-7e32-4603-af17-63dece2cbfcf",
  //         "name": "Trackonomics",
  //         "is_active": true,
  //         "business_unit_id": "ad046b46-538b-42cb-aa54-c3d158875ed6",
  //         "details": "",
  //         "metadata": [],
  //         "created_at": "2021-10-09T23:45:06.448437+00:00",
  //         "last_updated_at": "2021-10-09T23:45:06.448450+00:00"
  //     },
  //     "product_metadata": {
  //        "81bbdae2-81fb-4d95-b643-a0679795a2a4": {
  //            "link": "https:\/\/cuup.pxf.io\/c\/%3Fvariant%3D16586568138818",
  //            "network_name": "ir",
  //            "appended_link": "https:\/\/cuup.pxf.io\?u=https%3A%2F%2Fup.com%&subId3=xid:{xid}"
  //        }
  //     }
  // }

  const dan = getAffiliateNetwork(link);

  let network = dan?.product_metadata?.network_name ?? dan?.metadata?.trackonomics?.network_name;
  network = network?.toLowerCase();

  const cipd1 = 'hearstcorp_9eb67';
  const cipd2 = HRST?.commerce?.affiliate?.trackonomics?.cipd2 ?? 'TEMPLATEERROR';

  const customerId = `${cipd1}_${cipd2}`;

  const metadata = buildTrackingPayload(link);
  metadata.contentProductId = link.getAttribute('data-vars-ga-product-id');
  metadata.productRetailerId = link.getAttribute('data-vars-ga-product-retailer-id');
  metadata.mediaRole = link.getAttribute('data-vars-ga-media-role');
  metadata.callToAction = link.getAttribute('data-vars-ga-call-to-action');
  metadata.productBrand = link.getAttribute('data-vars-ga-product-brand');
  metadata.productPrice = link.getAttribute('data-vars-ga-product-price');
  metadata.sem3Brand = link.getAttribute('data-vars-ga-product-sem3-brand');
  metadata.sem3Category = link.getAttribute('data-vars-ga-product-sem3-category');
  metadata.sem3Id = link.getAttribute('data-vars-ga-product-sem3-id');

  // Rewrite linkTreatment if there is first product button
  if (!(/^(psv|mr\d|mrcust\d).*$/.test(link.getAttribute('data-vars-ga-link-treatment')))) {
    metadata.linkTreatment = link.getAttribute('data-vars-ga-link-treatment');
  }

  const elementXid = getSetXid(link);

  let anchorOrig = dan?.product_metadata?.[dan?.site_id]?.link ?? 'TEMPLATEERROR';
  SUBID_URL_PLACEHOLDERS.forEach((t) => {
    anchorOrig = anchorOrig.replace(t, cipd2);
  });

  return {
    env: cipd1,
    id: elementXid,
    app_key: 'gsd65fgr0hgTTT',
    property_id: 'PROPERTY_ID',
    customer_id: customerId,
    cidp1: cipd1,
    cidp2: cipd2,
    event_type: 'click',
    network,
    data: {
      attr_forwarding: getQueryParams(window.location.href),
      metadata,
      property_id: 'PROPERTY_ID',
      customer_id: customerId,
      cidp1: cipd1,
      cidp2: cipd2,
      source: window.location.href,
      event_type: 'click',
      referrer: document.referrer ?? '',
      uuid: elementXid,
      link_replaced: false,
      anchor_orig: anchorOrig,
      client_time: new Date().toISOString(),
      anchor_id: link.getAttribute('data-vars-ga-product-id'),
      anchor_href: link.href,
      anchor_label: link.getAttribute('data-vars-ga-call-to-action'),
      page_title: document.title,
      spot_id: '',
      network,
    },
  };
};
