import { getSettingsValue } from '../../utils';
import { log } from '../utils';
import { loadScriptIfConsent } from '../../gdpr';

export const loadSkimlinks = (skimlinksConfig) => {
  log('running skimlinks.MAG-ES.js');

  const skimURL = getSettingsValue(skimlinksConfig, 'url');

  if (skimURL) {
    setTimeout(() => {
      loadScriptIfConsent(skimURL, null);
    }, 3000);
  }
};

export default { loadSkimlinks };
