/* eslint no-continue: 0 */
import {
  getValue, getParam, Cookies, MD5,
} from 'ads-and-analytics/src/utils';
import { NETWORKS } from '../constants';
import { debugging, logging } from '../logger';
import {
  initHRST,
  parseLinkTreatment,
} from '../utils';
import {
  buildOWebVariationIds,
  buildOFSVariationTags,
} from '../optimizely/utils';

/**
 * Config from https://github.com/Hearst-Digital/ADTECH_SITE_JS/blob/master/ensighten/amazon_append.js#L4
 * to build subtag value string.
 */
const CONFIG = {
  getCookie(key) {
    /**
     * Returns value from _amznClk cookie if corresponding key is found
     * key - string of key used in cookie value
     */
    const nameEQ = '_amznClk=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        let result = c.substring(nameEQ.length, c.length);
        result = result.split('-');
        if (key === result[0]) {
          result.shift(1);
          return result.join('-');
        }
      }
    }
    return '';
  },
  getSrcVal() {
    // Check for 'utm_campaign' query parameter
    const utmCampaign = getParam('utm_campaign');
    if (utmCampaign) { return utmCampaign; }

    // Check for campaign property in 'gpt_utm' cookie
    const utmCookie = Cookies.get('gpt_utm');
    if (utmCookie) {
      const utmObj = JSON.parse(utmCookie);
      if (utmObj.campaign) { return utmObj.campaign; }
    }

    // Check for 'src' query parameter
    const src = getParam('src');
    if (src) { return src; }

    // Check for 'gpt_src' cookie
    const gptSrc = Cookies.get('gpt_src');
    if (gptSrc) { return gptSrc; }

    // Default to empty string
    return '';
  },
};

/**
 * Function to build Publisher's Signature which will be used to track sub-sites and members.
 *
 * @returns {string}
 */
export function getSubtagValue(el) {
  const HRST = initHRST();
  const icxSiteId = getValue('site.icxSiteId', HRST, '');

  let articleId = getValue('article.id', HRST, false);
  const srcVal = CONFIG.getSrcVal();
  const fbclid = getParam('fbclid') || CONFIG.getCookie('fbclid');
  const gclid = getParam('gclid') || CONFIG.getCookie('gclid');
  const msclkid = getParam('msclkid') || CONFIG.getCookie('msclkid');
  const { experimentId, variationId } = buildOWebVariationIds(HRST);
  let clickHash = '';

  if (articleId && icxSiteId) {
    const artIdArr = articleId.split('.');
    let artType = artIdArr[1].substring(0, 1);
    if (artType === 'l') {
      artType = 'g';
    }
    articleId = `${icxSiteId}.${artType}.${artIdArr[2]}`;

    if (gclid) {
      clickHash = MD5(gclid);
    }
    if (fbclid) {
      clickHash = MD5(fbclid);
    }
    if (msclkid) {
      clickHash = MD5(msclkid);
    }

    const lt = parseLinkTreatment(el);
    let ofsTags = buildOFSVariationTags(HRST)
      .map(([key, value]) => `${key}|${value}`)
      .join('[');
    ofsTags = ofsTags.length ? `[${ofsTags}` : '';
    return `[artid|${articleId}[src|${srcVal}[ch|${clickHash}[lt|${lt}[optxid|${experimentId}[optvid|${variationId}${ofsTags}`;
  }

  return '';
}

export function addSubtagValue(href, subtagVal) {
  if (!subtagVal) {
    return href;
  }
  const url = new URL(href);
  url.searchParams.set('u1', subtagVal);
  return url.toString();
}

export const debug = (...msg) => {
  debugging(`🉐 ${NETWORKS.rakuten}:`, ...msg);
};

export const log = (...msg) => {
  logging(`🉐 ${NETWORKS.rakuten}:`, ...msg);
};
