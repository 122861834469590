import { NETWORKS } from '../../constants';
import { setObserver, setTrackingListener } from '../../utils';
import { buildHandleObserver, debug, buildResetTracking } from '../utils';

const domain = 'amazon.';
const handleObserver = buildHandleObserver(domain, {
  getSrcVal: () => '',
  buildAscSubTag: () => '',
});
const resetTracking = buildResetTracking(domain);

export const amazonUKForumsInit = () => {
  debug('running amazon-append.MAG-UK-Forums.js');

  const networkName = `${NETWORKS.amazon} UK-Forums`;
  setObserver(handleObserver, networkName);
  setTrackingListener(resetTracking, networkName);
  handleObserver(document, true);
};
