import { loadScriptIfConsent } from '../../gdpr';
import { getSettingsValue } from '../../utils';
import { log } from '../utils';

export const loadSkimlinks = (skimlinksConfig) => {
  log('running skimlinks.MAG-NL.js');

  const skimURL = getSettingsValue(skimlinksConfig, 'url');

  if (skimURL) {
    setTimeout(() => {
      loadScriptIfConsent(skimURL, null);
    }, 3000);
  }
};

export default { loadSkimlinks };
