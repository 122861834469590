/**
 * Build Optimizely web variation ids
 * @param {object} HRST
 * @returns {object}
 */
export const buildOWebVariationIds = (HRST) => {
  const { experimentId = '', variationId = '' } = HRST.abTestingWeb ?? {};
  return { experimentId, variationId };
};

/**
 * Build Optimizely Web variation tags
 * @param {object} HRST
 * @returns {array}
 */
export const buildOWebVariationTags = (HRST) => {
  const { experimentId, variationId } = buildOWebVariationIds(HRST);
  let tags = [];
  if (experimentId && variationId) {
    tags = [
      ['optxid', experimentId],
      ['optvid', variationId],
    ];
  }
  return tags;
};

/**
 * Build Optimizely full stack variation tags
 * @param {object} HRST
 * @returns {array}
 */
export const buildOFSVariationTags = (HRST) => {
  const tags = [];
  const fsFlags = HRST?.abTestingFullStack;
  if (Array.isArray(fsFlags)) {
    fsFlags.forEach((f) => {
      const [ex, vr] = f.split(':');
      tags.push(['ofsxid', ex]);
      tags.push(['ofsvid', vr]);
    });
  }
  return tags;
};

/**
 * Build Optimizely full stack variation ids
 * @param {object} HRST
 * @returns {array}
 */
export const buildOVariationTags = (HRST) => [
  ...buildOWebVariationTags(HRST),
  ...buildOFSVariationTags(HRST),
];
