import { NETWORKS } from '../constants';
import { debugging, logging } from '../logger';

/** Returns string with random letters */
const getRandomLetters = (count) => {
  let res = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  for (let i = 0; i < count; i += 1) {
    res += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return res;
};

/** Returns mostly random XID
 * XID generation
 *   • The XID token will need to be populated dynamically on the front end layer,
 *     {XID} will be the value to replace
 *       ◦ Format >> fr{Epochtime}abc
 *       ◦ Epochtime + random 3 letters (Lowercase)
 *       ◦ Example >> fr1631515133jei
 *   • The Token must be unique per every link on page
 *   • The XID’s needs to be refreshed on every page load
 * @returns {string} "random" identifier
 */
export const generateXID = () => {
  const randomLetters = getRandomLetters(3);

  // TODO: lets start with Date.now(). We can divide by 1000 anytime later
  const epochTime = Date.now();

  return `fr${epochTime}${randomLetters}`;
};

export const debug = (...msg) => {
  debugging(`${NETWORKS.trackonomics}:`, ...msg);
};

export const log = (...msg) => {
  logging(`${NETWORKS.trackonomics}:`, ...msg);
};

export const error = (...msg) => {
  console.error(`${NETWORKS.trackonomics}:`, ...msg);
};
