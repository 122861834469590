import { NETWORKS } from '../constants';
import { debugging, logging } from '../logger';

export const debug = (...msg) => {
  debugging(`${NETWORKS.skimlinks}:`, ...msg);
};

export const log = (...msg) => {
  logging(`${NETWORKS.skimlinks}:`, ...msg);
};

export const error = (...msg) => {
  console.error(`${NETWORKS.skimlinks}:`, ...msg);
};
