import { getSettingsValue } from '../../utils';
import { log } from '../utils';
import { loadScriptIfConsent } from '../../gdpr';

export const loadSkimlinks = (skimlinksConfig) => {
  log('running skimlinks.MAG-TW.js');

  if (document.location.search.match(/(arb|mgu)_video_na/i)) return;

  const skimURL = getSettingsValue(skimlinksConfig, 'url');

  // keep reference to original url for analytics purposes
  function copyUrl() {
    const classes = [
      '.content-container a.body-btn-link',
      '.content-container a.product-btn-link',
    ];
    document.querySelectorAll(classes.join(', ')).forEach((link) => {
      const originalHref = link.getAttribute('href');
      const dataHref = link.getAttribute('data-href');
      if (originalHref && !dataHref) {
        link.setAttribute('data-href', originalHref);
      }
    });
  }

  copyUrl();

  if (skimURL !== '') {
    setTimeout(() => {
      loadScriptIfConsent(skimURL, null);
    }, 3000);
  }
};

export default { loadSkimlinks };
