import { getQueryParam } from './utils';

export const NETWORKS = {
  amazon: 'AMAZON',
  rakuten: 'RAKUTEN',
  skimlinks: 'SKIMLINKS',
  trackonomics: 'TRACKONOMICS',
  afflinks: 'AFFLINKS',
};

export const DEBOUNCE_TIMEOUT = ~~getQueryParam('debounceTimeout') || 250;
