// Inspired by https://github.com/faisalman/ua-parser-js/blob/master/src/main/ua-parser.mjs.
const TYPE = 'type';
const MOBILE = 'mobile';
const NAME = 'name';
const ANDROID = 'Android';
const IOS = 'iOS';

const processMatches = (matches, props) => {
  if (!matches) return null;

  return props.reduce((prev, q, k) => {
    // check if given property is actually array
    if (typeof q === 'object' && q.length > 0) {
      if (q.length === 2) {
        // assign given value, ignore regex match
        const [key, value] = q;
        return { ...prev, [key]: value };
      }
    } else {
      return { ...prev, [q]: matches[k + 1] || undefined };
    }
    return prev;
  }, {});
};

const rgxMapper = function (ua, arrays) {
  if (!ua || !arrays) return {};

  let result = {};
  let i = 0;
  let matches;

  // loop through all regexes maps
  while (i < arrays.length && !matches) {
    const regex = arrays[i]; // even sequence (0,2,4,..)
    const props = arrays[i + 1]; // odd sequence (1,3,5,..)
    let j = 0;

    // try matching uastring with regexes
    while (j < regex.length && !matches) {
      if (!regex[j]) {
        break;
      }
      matches = processMatches(regex[j].exec(ua), props);
      if (matches) result = { ...result, ...matches };
      j += 1;
    }
    i += 2;
  }

  return result;
};

const device = [
  [
    /\b((?:s[cgp]h|gt|sm)-\w+|sc[g-]?[\d]+a?|galaxy nexus)/i,
    /samsung[- ]([-\w]+)/i,
    /sec-(sgh\w+)/i,
  ],
  [[TYPE, MOBILE]],
  [
    // Apple
    /(?:\/|\()(ip(?:hone|od)[\w, ]*)(?:\/|;)/i, // iPod/iPhone
  ],
  [[TYPE, MOBILE]],
  [
    // Sharp
    /\b(sh-?[altvz]?\d\d[a-ekm]?)/i,
  ],
  [[TYPE, MOBILE]],
  [
    /(?:huawei|honor)([-\w ]+)[;)]/i,
    /\b(nexus 6p|\w{2,4}e?-[atu]?[ln][\dx][012359c][adn]?)\b(?!.+d\/s)/i,
  ],
  [[TYPE, MOBILE]],
  [
    // Xiaomi
    /\b(poco[\w ]+)(?: bui|\))/i, // Xiaomi POCO
    /\b; (\w+) build\/hm\1/i, // Xiaomi Hongmi 'numeric' models
    /\b(hm[-_ ]?note?[_ ]?(?:\d\w)?) bui/i, // Xiaomi Hongmi
    /\b(redmi[-_ ]?(?:note|k)?[\w_ ]+)(?: bui|\))/i, // Xiaomi Redmi
    /\b(mi[-_ ]?(?:a\d|one|one[_ ]plus|note lte|max|cc)?[_ ]?(?:\d?\w?)[_ ]?(?:plus|se|lite)?)(?: bui|\))/i, // Xiaomi Mi
  ],
  [[TYPE, MOBILE]],
  [
    // OPPO
    /; (\w+) bui.+ oppo/i,
    /\b(cph[12]\d{3}|p(?:af|c[al]|d\w|e[ar])[mt]\d0|x9007|a101op)\b/i,
  ],
  [[TYPE, MOBILE]],
  [
    // Vivo
    /vivo (\w+)(?: bui|\))/i,
    /\b(v[12]\d{3}\w?[at])(?: bui|;)/i,
  ],
  [[TYPE, MOBILE]],
  [
    // Realme
    /\b(rmx[12]\d{3})(?: bui|;|\))/i,
  ],
  [[TYPE, MOBILE]],
  [
    // Motorola
    /\b(milestone|droid(?:[2-4x]| (?:bionic|x2|pro|razr))?:?( 4g)?)\b[\w ]+build\//i,
    /\bmot(?:orola)?[- ](\w*)/i,
    /((?:moto[\w() ]+|xt\d{3,4}|nexus 6)(?= bui|\)))/i,
  ],
  [[TYPE, MOBILE]],
  [
    /(lm(?:-?f100[nv]?|-[\w.]+)(?= bui|\))|nexus [45])/i,
    /\blg[-e;/ ]+((?!browser|netcast|android tv)\w+)/i,
    /\blg-?([\d\w]+) bui/i,
  ],
  [[TYPE, MOBILE]],
  [
    // Nokia
    /(?:maemo|nokia).*(n900|lumia \d+)/i,
    /nokia[-_ ]?([-\w.]*)/i,
  ],
  [[TYPE, MOBILE]],
  [
    /droid.+; (pixel[\daxl ]{0,6})(?: bui|\))/i, // Google Pixel
  ],
  [[TYPE, MOBILE]],
  [
    // Sony
    /droid.+ (a?\d[0-2]{2}so|[c-g]\d{4}|so[-gl]\w+|xq-a\w[4-7][12])(?= bui|\).+chrome\/(?![1-6]{0,1}\d\.))/i,
  ],
  [[TYPE, MOBILE]],
  [
    // OnePlus
    / (kb2005|in20[12]5|be20[12][59])\b/i,
    /(?:one)?(?:plus)? (a\d0\d\d)(?: b|\))/i,
  ],
  [[TYPE, MOBILE]],
  [
    /((?:sd|kf)[0349hijorstuw]+)( bui|\)).+silk\//i, // Fire Phone
  ],
  [[TYPE, MOBILE]],
  [
    /\b((?:bb[a-f]|st[hv])100-\d)/i,
    /\(bb10; (\w+)/i, // BlackBerry 10
  ],
  [[TYPE, MOBILE]],
  [/ (z[bes]6[027][012][km][ls]|zenfone \d\w?)\b/i],
  [[TYPE, MOBILE]],
  [
    /(htc)[-;_ ]{1,2}([\w ]+(?=\)| bui)|\w+)/i, // HTC

    // ZTE
    /(zte)[- ]([\w ]+?)(?: bui|\/|\))/i,
    /(alcatel|geeksphone|nexian|panasonic(?!(?:;|.))|sony(?!-bra))[-_ ]?([-\w]*)/i, // Alcatel/GeeksPhone/Nexian/Panasonic/Sony
  ],
  [[TYPE, MOBILE]],
  [
    // Meizu
    /droid.+; (m[1-5] note) bui/i,
    /\bmz-([-\w]{2,})/i,
  ],
  [[TYPE, MOBILE]],
  [
    // MIXED
    /(blackberry|benq|palm(?=-)|sonyericsson|acer|asus|dell|meizu|motorola|polytron)[-_ ]?([-\w]*)/i,
    // BlackBerry/BenQ/Palm/Sony-Ericsson/Acer/Asus/Dell/Meizu/Motorola/Polytron
    /(hp) ([\w ]+\w)/i, // HP iPAQ
    /(asus)-?(\w+)/i, // Asus
    /(microsoft); (lumia[\w ]+)/i, // Microsoft Lumia
    /(lenovo)[-_ ]?([-\w]+)/i, // Lenovo
    /(jolla)/i, // Jolla
    /(oppo) ?([\w ]+) bui/i, // OPPO
  ],
  [[TYPE, MOBILE]],
  [
    /droid [\d.]+; (fp\du?)(?: b|\))/i, // Fairphone
  ],
  [[TYPE, MOBILE]],
  [
    /(sprint) (\w+)/i, // Sprint Phones
  ],
  [[TYPE, MOBILE]],
  [
    /(kin\.[onetw]{3})/i, // Microsoft Kin
  ],
  [[TYPE, MOBILE]],
  [/droid.+; (ec30|ps20|tc[2-8]\d[kx])\)/i],
  [[TYPE, MOBILE]],
  [
    /droid .+?; ([^;]+?)(?: bui|\) applew).+? mobile safari/i, // Android Phones from Unidentified Vendors
  ],
  [[TYPE, MOBILE]],
  [
    /(phone|mobile(?:[;/]| [ \w/.]*safari)|pda(?=.+windows ce))/i, // Unidentifiable Mobile
  ],
  [[TYPE, MOBILE]],
];

const os = [
  [
    // iOS/macOS
    /ip[honead]{2,4}\b(?:.*os ([\w]+) like mac|; opera)/i, // iOS
    /(?:ios;fbsv\/|iphone.+ios[/ ])([\d.]+)/i,
    /cfnetwork\/.+darwin/i,
  ],
  [[NAME, 'iOS']],
  [
    // Mobile OSes
    /droid ([\w.]+)\b.+(android[- ]x86|harmonyos)/i, // Android-x86/HarmonyOS
  ],
  ['t', NAME],
  [
    // Android/WebOS/QNX/Bada/RIM/Maemo/MeeGo/Sailfish OS
    /(android|webos|qnx|bada|rim tablet os|maemo|meego|sailfish)[-/ ]?([\w.]*)/i,
    /(blackberry)\w*\/([\w.]*)/i, // Blackberry
    /(tizen|kaios)[/ ]([\w.]+)/i, // Tizen/KaiOS
    /\((series40);/i, // Series 40
  ],
  [NAME],
];

let uaDetails;
const getUaDetails = () => {
  if (!uaDetails) {
    const { userAgent } = window.navigator;
    uaDetails = {
      ...rgxMapper(userAgent, device),
      ...rgxMapper(userAgent, os),
    };
  }

  return uaDetails;
};

export const isAndroid = () => getUaDetails()[NAME] === ANDROID;
export const isIOS = () => getUaDetails()[NAME] === IOS;
export const isMobile = () => getUaDetails()[TYPE] === MOBILE;
