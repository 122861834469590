/**
* If you need to modify this file you maybe need to modify one of these
* https://github.com/Hearst-Digital/ADTECH_SITE_JS/blob/master/ensighten/amazon_append_es.js
* also
*/

import { NETWORKS } from '../../constants';
import { setObserver, setTrackingListener } from '../../utils';
import { buildHandleObserver, debug, buildResetTracking } from '../utils';

const domain = 'amazon.';
const handleObserver = buildHandleObserver(domain);
const resetTracking = buildResetTracking(domain);

export const amazonESInit = () => {
  debug('running amazon-append.MAG-ES.js');

  const networkName = `${NETWORKS.amazon} ES`;
  setObserver(handleObserver, networkName);
  setTrackingListener(resetTracking, networkName);
  handleObserver(document, true);
};
