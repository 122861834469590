import { log } from './utils';
import { initBundle } from '../gdpr';
import { docReady, initHRST } from '../utils';
import { amazonUSInit } from './runners/amazon-append.HMG-US';
import { amazonESInit } from './runners/amazon-append.MAG-ES';
import { amazonITInit } from './runners/amazon-append.MAG-IT';
import { amazonUKInit } from './runners/amazon-append.MAG-UK';
import { amazonUKForumsInit } from './runners/amazon-append.MAG-UK-Forums';
import { NETWORKS } from '../constants';

const getMarketRunner = (market) => {
  switch (market) {
    case 'HMG-US':
      return amazonUSInit;
    case 'HMG-INTL':
      // generic international that uses same logic as US
      return amazonUSInit;
    case 'MAG-ES':
      return amazonESInit;
    case 'MAG-IT':
      return amazonITInit;
    case 'MAG-UK':
      return amazonUKInit;
    case 'MAG-UK-Forums':
      return amazonUKForumsInit;
    default:
      log(`'${market}' market not found, switched to HMG-US`);
      return amazonUSInit;
  }
};

function amazonAppendBundle() {
  // complete new file
  const HRST = initHRST();
  const market = HRST?.commerce?.affiliate?.amazon?.market || false;
  const runner = getMarketRunner(market);
  if (runner) {
    log(`RUN FOR MARKET ${market}`);
    runner();
  } else {
    log('market not found for affiliate amazon:', market);
  }
}

docReady(initBundle(amazonAppendBundle, NETWORKS.amazon));
