// Allows log ino console if True
export const isLoggingEnabled = /affiliateLogging/.test(document.location.search);

export const logging = (...msg) => {
  if (isLoggingEnabled) {
    console.log(...msg);
  }
};

export const debugging = (...msg) => {
  if (isLoggingEnabled) {
    console.debug(...msg);
  }
};
