import tcfConfig from 'ads-and-analytics/src/utils/tcf-config';
import { Events, Cookies, getValue } from 'ads-and-analytics/src/utils';
import { debugging, logging } from './logger';
import { canProcessNode } from './nodes';
import { buildOWebVariationIds, buildOFSVariationTags } from './optimizely/utils';

/**
 * users tracking is only enabled if
 * 1. user site env does not require gdpr compliance or
 * 2. user site with gdpr compliance as consented
 *
 *
 * @type {false|*}
 */
export const affiliateTrackingEnabled = () => {
  if (!window.getTCFConsent) {
    tcfConfig();
  }

  const gdpr = Object.prototype.hasOwnProperty.call(window, 'GDPR') && window.GDPR;
  if (!gdpr) {
    // gdpr does not apply
    return true;
  }
  // consent category is C0003
  return window.getTCFConsent('category', 'C0003', () => {
    window._fireTrackingEvent(true);
  });
};

const initExperimentData = (experimentStates) => {
  let experimentId = null;
  let variationId = null;
  if (!experimentStates || experimentStates.length <= 1) {
    return { experimentId, variationId };
  }
  if (experimentStates[1]?.id) {
    experimentId = experimentStates[1]?.id;
  }
  if (experimentStates[1]?.variation?.id) {
    variationId = experimentStates[1]?.variation?.id;
  }
  return { experimentId, variationId };
};

const getWebOptimizelyData = () => {
  logging('AB Testing: Optimizely get function is ready', window?.optimizely?.get);
  const state = window.optimizely.get('state');
  const [experimentStates] = Object.entries(
    state.getExperimentStates((s) => s.isActive && !s.reason),
  );
  return initExperimentData(experimentStates);
};

export const initHRST = () => {
  window.HRST = window.HRST ?? {};

  if (window.HRST?.commerce?.affiliate?.initialized) {
    logging('HRST is initialized... ');
    return window.HRST;
  }

  if (!window.HRST?.commerce) {
    window.HRST.commerce = {};
  }
  if (!window.HRST?.commerce?.affiliate) {
    window.HRST.commerce.affiliate = {};
  }

  if (!window.HRST?.abTestingWeb) {
    window.HRST.abTestingWeb = {};
  }

  if (window?.optimizely?.get) {
    try {
      window.HRST.abTestingWeb = getWebOptimizelyData();
    } catch (e) {
      logging('AB Testing: Optimizely get function error', window?.optimizely?.get, e);
    }
  } else {
    logging('AB Testing: Optimizely get function is not ready yet', window?.optimizely?.get);
    window.optimizely = window.optimizely || [];
    const optimizelyConfig = {
      type: 'addListener',
      filter: {
        type: 'lifecycle',
        name: 'campaignDecided',
      },
      handler: (event) => {
        logging('AB Testing: Web Optimizely initialized', { event });
        window.HRST.abTestingWeb = getWebOptimizelyData();
        Events.fire(window, 'reset-data-tracking');
      },
    };
    window.optimizely.push(optimizelyConfig);
    logging('AB Testing: Pushed config object', { optimizelyConfig });
  }

  if (!window.HRST?.commerce?.affiliate?.trackingEnabled) {
    window.HRST.commerce.affiliate.trackingEnabled = affiliateTrackingEnabled();
  }

  if (!window.HRST?.abTestingFullStack) {
    window.HRST.abTestingFullStack = [];
  }

  window.HRST.commerce.affiliate.initialized = true;
  return window.HRST;
};

/** Returns Object fro 'data-affiliate-network' attribute */
export const getAffiliateNetwork = (link) => {
  const val = link.getAttribute('data-affiliate-network');
  if (!val) {
    debugging('getAffiliateNetwork - NOT FOUND', val);
    return null;
  }
  try {
    const result = JSON.parse(val);
    debugging(`${result?.network?.name?.toUpperCase() ?? ''} getAffiliateNetwork`.trim(), result);
    return result;
  } catch (e) {
    debugging('getAffiliateNetwork ERROR', link, val, e);
    return undefined;
  }
};

export const getSettingsValue = (affiliateConfig, valName) => {
  // Example:
  // affiliateConfig = {
  //   'market': 'MAG-UK',
  //   'default': {
  //     'tag': 'digitalspy.com-21',
  //   },
  //   'section': {
  //     'style-beauty': {
  //       'tag': 'cosmopolitan.com',
  //     },
  //   },
  //   'domains': {
  //     'cosmopolitan-7975.kubefeature.hearstapps.net': {
  //       'default': {
  //         'tag': 'forums.digitalspy.com-21',
  //       },
  //       'section': {
  //         'style-beauty': {
  //           'tag': 'forums.cosmopolitan-7975.kubefeature.hearstapps.net',
  //         },
  //       },
  //     },
  //   },
  // };

  const HRST = window.HRST ?? {};

  const siteSection = HRST?.article?.section?.prefix || false;
  const domainSettings = affiliateConfig?.domains?.[document.location.hostname];

  const defaultSetting = domainSettings?.default ?? affiliateConfig?.default;
  const sectionSetting = domainSettings?.section?.[siteSection]
    ?? affiliateConfig?.section?.[siteSection];

  const settings = sectionSetting ?? defaultSetting;

  return settings?.[valName] ?? '';
};

/**
 * extract query parameter from the document url
 * @param {string} variable
 */
export const getQueryParam = (variable) => new URL(document.location).searchParams.get(variable) || '';

/**
 * Returns value from query or gpt_utm cookie if corresponding key is found
 * @param {string} key - string of key used in query or cookie value
 */
export const getUtmVal = (key) => {
  // Check for key query parameter
  const utmValue = getQueryParam(key);
  if (utmValue) {
    return utmValue;
  }

  // Check for campaign property in 'gpt_utm' cookie
  const utmCookie = Cookies.get('gpt_utm');
  if (utmCookie) {
    const utmObj = JSON.parse(utmCookie);
    const utmObjKey = key.split('_')[1] || '';
    if (Object.prototype.hasOwnProperty.call(utmObj, utmObjKey)) {
      return utmObj[utmObjKey];
    }
  }

  // Default to empty string
  return '';
};

/**
 * Returns value from _amznClk cookie if corresponding key is found
 * @param {string} key - string of key used in cookie value
 */
const getCookie = (key) => {
  const nameEQ = '_amznClk=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      let result = c.substring(nameEQ.length, c.length);
      result = result.split('-');
      if (key === result[0]) {
        result.shift(1);
        return result.join('-');
      }
    }
  }
  return '';
};

export const getDataLayerVal = (val) => {
  let dataLayer = {};
  try {
    dataLayer = JSON.parse(document.getElementById('data-layer').innerText);
  } catch (e) {
    // nothing
  }
  return getValue(val, dataLayer, '');
};

/**
 * Extracts link treatment attribute and preprocesses values.
 *
 * @param {HTMLElement} element
 * @param {boolean} stripNotSet
 * @return {string}
 */
export const parseLinkTreatment = (element) => (element.getAttribute('data-vars-ga-link-treatment') || '')
  .split('|')
  .map((part) => part.trim())
  .filter((part) => part !== '(not set)')[0] || '';

export const parseClickId = (element) => element.getAttribute('data-vars-ga-axid') || '';

/**
 * Build out tracking payload for trackonomics
 */
export const buildTrackingPayload = (element) => {
  // if (!affiliateTrackingEnabled()) {
  //   // tracking payload should not build if not enabled
  //   return {};
  // }
  // do not capture any tracking data if user has not consented
  const enabled = affiliateTrackingEnabled();

  const utmSource = getUtmVal('utm_source');
  const utmCampaign = getUtmVal('utm_campaign');
  const utmMedium = getUtmVal('utm_medium');

  const fbclid = getQueryParam('fbclid') || getCookie('fbclid');
  const gclid = getQueryParam('gclid') || getCookie('gclid');
  const msclkid = getQueryParam('msclkid') || getCookie('msclkid');

  // Get referrer domain
  let refDomain = '';
  const refUrl = document.referrer;
  if (refUrl) {
    const [, , third] = refUrl.split('/');
    refDomain = third;
  }

  const contentId = getDataLayerVal('content.id') || getDataLayerVal('listing.id') || window.HRST.contentId;
  const contentProductId = element.getAttribute('data-vars-ga-product-id') || '';
  const productRetailerId = element.getAttribute('data-vars-ga-product-retailer-id') || '';

  const linkTreatment = parseLinkTreatment(element);
  const { experimentId: oplyExpId, variationId: oplyVarId } = buildOWebVariationIds(window.HRST);
  const ofsTags = buildOFSVariationTags(window.HRST);

  const payload = {
    utmSource: enabled ? utmSource : '',
    utmCampaign: enabled ? utmCampaign : '',
    utmMedium: enabled ? utmMedium : '',
    gclid: enabled ? gclid : '',
    msclkid: enabled ? msclkid : '',
    fbclid: enabled ? fbclid : '',
    refDomain,
    contentId,
    contentProductId,
    productRetailerId,
    linkTreatment,
    axid: parseClickId(element),
    oplyExpId: enabled ? oplyExpId : '',
    oplyVarId: enabled ? oplyVarId : '',
    ofsTags: enabled ? ofsTags : [],
  };
  debugging('AFFILIATE:', 'utils.buildTrackingPayload', { element, payload });
  return payload;
};

/**
 * Build out tracking data for skimlinks urls
 */
export const buildTrackingData = (element) => {
  const {
    utmSource,
    utmCampaign,
    utmMedium,
    gclid,
    msclkid,
    fbclid,
    refDomain,
    contentId,
    contentProductId,
    productRetailerId,
    linkTreatment,
    axid,
    oplyExpId,
    oplyVarId,
    ofsTags,
  } = buildTrackingPayload(element);

  const out = `[${[
    `utm_source|${utmSource}`,
    `utm_campaign|${utmCampaign}`,
    `utm_medium|${utmMedium}`,
    `gclid|${gclid}`,
    `msclkid|${msclkid}`,
    `fbclid|${fbclid}`,
    `refdomain|${refDomain}`,
    `content_id|${contentId}`,
    `content_product_id|${contentProductId}`,
    `product_retailer_id|${productRetailerId}`,
    `lt|${linkTreatment}`,
    `axid|${axid}`,
    `optxid|${oplyExpId}`,
    `optvid|${oplyVarId}`,
    ...(ofsTags.map(([key, value]) => `${key}|${value}`)),
  ].join('[')}`;
  debugging('AFFILIATE:', 'utils.buildTrackingData', { element, data: out });
  return out;
};

/** Set observer to run function
 *
 * @param {Function} func function to run inside observer
 * @param {string} networkName Affiliate network name
 */
export const setObserver = (func, networkName) => {
  const HRST = initHRST();

  let { observers } = HRST.commerce.affiliate;
  if (!observers) {
    observers = {};
    HRST.commerce.affiliate.observers = observers;
  }
  if (!observers[networkName.toLowerCase()]) {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes?.length) {
          mutation.addedNodes.forEach((node) => {
            if (canProcessNode(node)) {
              func(node);
              debugging(`${networkName}: run MutationObserver on`, node);
            }
          });
        }
      });
    });
    observer.observe(document.body, {
      subtree: true,
      childList: true,
      attributes: true,
      attributeFilter: [
        'data-amazon-ascsubtag',
        'data-skimlinks-tracking',
        'data-trackonomics-xid',
        'data-trackonomics-tracking',
        'data-afflinks-tracking',
      ],
    });

    observers[networkName.toLowerCase()] = observer; // eslint-disable-line no-param-reassign

    logging(networkName, 'initialize MutationObserver');
  }
};

/**
 * this observer only identifies when anchor tags have had their
 * data-affiliate-network attribute added. This should ONLY
 * happen to anchor tags
 */
export const setAffiliateNetworkAttributeObserver = (fn, networkName) => {
  const HRST = initHRST();

  let { observers } = HRST.commerce.affiliate;
  if (!observers) {
    observers = {};
    HRST.commerce.affiliate.affiliateNetworkObservers = observers;
  }
  if (!observers[networkName.toLowerCase()]) {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'data-affiliate-network') {
          const node = mutation.target;
          fn(node);
          debugging(`${networkName}: run AffiliateNetworkAttributeObserver on`, node);
        }
      });
    });
    observer.observe(document.body, { subtree: true, attributes: true });

    observers[networkName.toLowerCase()] = observer; // eslint-disable-line no-param-reassign

    logging(networkName, 'initialize setAffiliateNetworkAttributeObserver');
  }
};

export const debounce = (func, wait, immediate) => {
  let timeout;
  return function () {
    const context = this;
    const args = arguments; // eslint-disable-line prefer-rest-params
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

/**
 * This debouncer will collect all the calls and run them all but not only the latest
 * it will pass all input arguments as an array. Make sure if you func supports this
 * @param {function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @param {function} preHandler will be called before func
 * @returns {function} debounced function
 */
export const debounceCollect = (func, wait, immediate, preHandler, prefix) => {
  let timeout;
  let context;

  let args = [];

  const logPrefix = `${prefix ? `${prefix}:` : ''} [DEBOUNCER]`.trim();
  const reset = () => {
    debugging(logPrefix, 'reset', args.length);
    args = [];
  };

  const collect = (newArgs) => {
    args.push(newArgs);
    debugging(logPrefix, 'collect', args.length, newArgs);
  };

  const callFunc = () => {
    debugging(logPrefix, 'call', args);
    func.apply(context, preHandler ? preHandler(args) : args);
    reset();
  };

  return function debounced(...rest) {
    context = this;
    collect(rest);
    const later = function () {
      timeout = null;
      if (!immediate) {
        callFunc();
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      callFunc();
    }
  };
};

/** Returns true if network is disabled
 *
 * @param {'AMAZON'|'RAKUTEN'|'SKIMLINKS'|'TRACKONOMICS'|'all'} network Network name
 * @returns {boolean}
 */
export const isDisabled = (network = 'all') => {
  const values = getQueryParam('disableAffiliateBundle').toLowerCase().split(',');
  return values.includes('all') || values.includes(network.toLowerCase());
};

/**
 * Runs the function only and only if document is already available
 * @param {function} fn function to call
 */
export const docReady = (fn) => {
  // See if DOM is already available
  if (['interactive', 'complete'].includes(document.readyState)) {
    // Call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

export const setTrackingListener = (func, networkName) => {
  const HRST = initHRST();

  let { tracking } = HRST.commerce.affiliate;
  if (!tracking) {
    tracking = {};
    HRST.commerce.affiliate.tracking = tracking;
  }
  const networkNameLower = networkName.toLowerCase();
  if (!tracking[networkNameLower]) {
    tracking[networkNameLower] = func; // eslint-disable-line no-param-reassign
  }
  logging(networkName, 'initialize setTrackingListener');
  window.addEventListener('reset-data-tracking', func);
};

/**
 * used in conjunction with setTrackingListener
 * @param enabled
 */
export const fireTrackingEvent = (enabled) => {
  debugging('🔥🔥🔥 fireTrackingEvent', enabled);

  const affiliate = window.HRST?.commerce?.affiliate;
  let needsUpdate = false;
  if (!Object.prototype.hasOwnProperty.call(affiliate, 'trackingEnabled')) {
    // property did not exist, let's add it
    needsUpdate = true;
    initHRST();
  } else if (affiliate?.trackingEnabled !== enabled) {
    needsUpdate = true;
  }

  if (needsUpdate) {
    window.HRST.commerce.affiliate.trackingEnabled = enabled;
    /**
     * window.dispatchEvent(new CustomEvent('reset-data-tracking', { detail: { enabled: true } }));
     *
     * @type {CustomEvent<{enabled}>}
     */
    // Create a custom event
    const myEvent = new CustomEvent('reset-data-tracking', { detail: { enabled } });
    // Dispatch/Trigger the event
    window.dispatchEvent(myEvent);
    debugging('fireTrackingEvent - fired!', enabled);
  } else {
    debugging('fireTrackingEvent - skipped', enabled);
  }
};

window._fireTrackingEvent = debounce(fireTrackingEvent, 100, false);

export class AffiliateClickManager {
  constructor(name, trackingAttribute) {
    this.name = name;
    this.trackingAttribute = trackingAttribute;
    this.elements = [];
    document.addEventListener('app.generate-xid', this.processEvent.bind(this));
  }

  fireEvent() {
    document.dispatchEvent(new CustomEvent('app.generate-xid'));
  }

  processEvent(event) {
    debugging('processEvent', event, 'this', this);
    this.elements.forEach(this.generateClickID);
  }

  generateClickID(element) {
    if (element) {
      const uuid = crypto.randomUUID();
      element.setAttribute('data-vars-ga-axid', uuid);
      debugging('generateClickID', element, uuid);
    }
  }

  /**
   * @param {HTMLElement} element
   * @param {(element: HTMLElement) => void} processElement
   */
  register(element, processElement) {
    this.generateClickID(element);
    if (!this.elements.includes(element)) {
      this.elements.push(element);
      const fireMe = () => {
        // this.fireEvent.bind(this);
        // Need to wait GA call
        setTimeout(() => {
          // if element does not have the correct tracking attribute, bail
          if (!element.hasAttribute(this.trackingAttribute)) {
            return;
          }
          this.generateClickID(element);
          debugging('fireMe', element);
          if (processElement) {
            processElement(element);
          }
        }, 300);
      };
      element.addEventListener('click', fireMe.bind(this));
      element.addEventListener('contextmenu', fireMe.bind(this));
    }
  }
}

/**
 * checks if afflinks to be rendered on client side
 */
export const renderAfflinksClientSide = (() => {
  const HRST = initHRST();
  const siteFlags = HRST?.commerce?.flags?.site || {};
  const afflinksEnabled = getQueryParam('afflinks_enabled');
  const renderFlag = 'commerce.self-hosted.affiliate.render-afflinks-redirect';
  const afflinksClientSideEnabled = afflinksEnabled || siteFlags[renderFlag];
  return !!afflinksClientSideEnabled;
})();

/**
 * extract afflink path from data-affiliate-network attribute
 * @param {HTMLElement} element
 */
export const extractAfflinksPath = (element) => {
  const affiliateNetworkStr = element.getAttribute('data-affiliate-network') || '{}';
  try {
    const affiliateNetworkJson = JSON.parse(affiliateNetworkStr);
    const afflinkRedirect = affiliateNetworkJson?.afflink_redirect;
    if (afflinkRedirect) {
      const shortUrl = afflinkRedirect.startsWith('/_p/') ? afflinkRedirect : `/_p${afflinkRedirect}`;
      return shortUrl;
    }
  } catch (e) {
    debugging('extractAfflinksPath error parsing affiliate_network', e);
  }
  return '';
};

/**
 * check if element is to be rendered by afflinks at client side
 * @param {HTMLElement} element
 */
export const isClientSideAfflinkElement = (element) => {
  // if render is enabled in client side check if afflink path exists
  if (renderAfflinksClientSide) return !!extractAfflinksPath(element);
  return false;
};
