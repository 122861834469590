import { debugging } from './logger';

/* Be sure that node type in this array has querySelectorAll function
 ((typeof node?.querySelectorAll) === 'function') */
const ALLOWED_NODE_TYPES = [
  Node.ELEMENT_NODE,
  Node.DOCUMENT_NODE,
  // Node.DOCUMENT_FRAGMENT_NODE,
];

// Array of node names in lowerCase
const BLOCKED_NODE_NAMES = [
  // 'form',
  'iframe',
  'input',
  'journey-inline-newsletter',
  'script',
  'video',
];

// Array of RegEx to check elements id
const BLOCKED_IDS = [
  /^google_ads.*/,
  /^gpt_.*/,
];

// Array of RegEx to check elements classes
const BLOCKED_CLASSES = [
  /.*?breaker-ad.*/,
  /^glimmerPlayer$/,
  /^text_ad$/,
  /.*?vertical-ad.*/,
  /^video-js$/,
  /^vjs-.*/,
];

/**
 * Check if element id is in the blocked list
 * @param {string} id element id
 * @returns true if id in the blocked list
 */
const isIdBlocked = (id) => !!id && BLOCKED_IDS.some((regex) => regex.test(id));

/**
 * Check if className is in the blocked list
 * @param {DOMTokenList} classList list of classes
 * @returns true if class in the blocked list
 */
const isClassBlocked = (classList) => !!classList
  && [...classList].some((cn) => BLOCKED_CLASSES.some((regex) => regex.test(cn)));

/**
  * Returns true if we can process the node
  * @param {Node} node the node to check
  * @returns {boolean} returns true if we cad proceed
  */
export const canProcessNode = (node) => {
  if (!ALLOWED_NODE_TYPES.includes(node?.nodeType)) {
    if (node?.nodeType !== Node.TEXT_NODE) { // To many messages in video players
      debugging('NODE CHECKER:', 'skipped by node type', node);
    }
    return false;
  }

  if (BLOCKED_NODE_NAMES.includes(node?.nodeName?.toLowerCase())) {
    debugging('NODE CHECKER:', 'skipped by node name', node);
    return false;
  }

  if (isIdBlocked(node?.id)) {
    debugging('NODE CHECKER:', 'skipped by id', node);
    return false;
  }

  if (isClassBlocked(node?.classList)) {
    debugging('NODE CHECKER:', 'skipped by className', node);
    return false;
  }

  debugging('NODE CHECKER:', 'approved node', node);
  return true;
};

/**
 * This optimizer takes an array of all arguments form all
 * debounced function calls and prepare them to handler.
 * @param {[node:Node, firstRun:boolean][]} args
 * @returns {[nodes:Node[], firstRun:boolean]} return array with two elements: Node[] and boolean
 */
export const optimizeNodes = (args) => {
  debugging('[PRE HANDLER] input', args);

  // Find if at least one `firstRun === true` exists
  const firstRun = args.some(([, fr = false]) => fr);
  debugging('[PRE HANDLER] firstRun', firstRun);

  const nodeSet = args.reduce((acc, [node]) => {
    // `node` can be `undefined`. This means that it was a general
    // call to process the entire document
    debugging('[PRE HANDLER] acc, node', acc, node);
    acc.add(node ?? document);
    return acc;
  }, new Set());
  debugging('[PRE HANDLER] nodeSet', nodeSet);

  // If `nodeSet` contains a `document` node, we can skip the others
  const nodes = nodeSet.has(document) ? [document] : [...nodeSet];
  debugging('[PRE HANDLER] nodes', nodes);

  return [nodes, firstRun];
};

// /**
//  * Trying to make array smaller
//  * We are using debouncer with collector, so array can contain
//  *  - regular node - from mutation observer if node added
//  *  - undefined - when it comes from event and we should process document
//  *
//  * @param {Node[]} changedNodes
//  */
// export const optimizeNodes = (changedNodes) => {
//   debugging('[PRE HANDLER OLD]', 'input', changedNodes);
//   if (changedNodes?.includes(undefined) || changedNodes?.includes(document)) {
//     // if we should process whole document - we can skip others
//     debugging('[PRE HANDLER OLD]', 'return [document]');
//     return [document];
//   }
//   return changedNodes;
// };

/**
 * Search using selector in the array of nodes
 * @param {Node} nodes List of nodes
 * @param {string} selector selector for querySelectorAll()
 * @returns {array} List on founded elements
 */
export const queryNodesBySelector = (nodes, selector) => {
  const result = new Set();
  (new Set(nodes)).forEach((node) => {
    node.querySelectorAll(selector).forEach((el) => result.add(el));
  });
  return [...result];
};
