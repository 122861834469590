import { DEBOUNCE_TIMEOUT, NETWORKS } from '../constants';
import {
  getAffiliateNetwork,
  setObserver,
  setAffiliateNetworkAttributeObserver,
  docReady,
  debounceCollect,
} from '../utils';
import {
  addSubtagValue, debug, getSubtagValue, log,
} from './utils';
import {
  hasConsent, initBundle,
  registerEvents, revertBackAffiliate,
  rewriteAffiliatesDOM,
} from '../gdpr';
import { optimizeNodes, queryNodesBySelector } from '../nodes';

const SELECTOR = ['a[data-affiliate-url*="click.linksynergy.com"]'].join(',');
const OBSERVER_SELECTOR = 'a[data-affiliate-url*="click.linksynergy.com"]:not([data-rakuten-tracking])';

/**
 * Determines if element contains the data-affiliate-network
 * attribute and if that attribute's data network.name is Rakuten
 */
export const isRakuten = (element) => {
  const affiliateNetwork = getAffiliateNetwork(element);
  debug('isRakuten', affiliateNetwork);
  return affiliateNetwork?.network?.name === 'Rakuten';
};

export const handleRakutenElement = (element) => {
  revertBackAffiliate(element);

  const subtagVal = getSubtagValue(element);
  if (!subtagVal) {
    log('handleRakutenDOM - subtagVal is empty', element);
    return;
  }
  element.setAttribute('href', addSubtagValue(element.dataset.affiliateUrl, subtagVal));
  element.setAttribute('data-rakuten-tracking', subtagVal);
};

export const handleRakutenDOM = (element) => {
  if (!isRakuten(element)) {
    log('handleRakutenDOM - ignored', element);
    return;
  }
  debug('handleRakutenDOM', element);
  if (!hasConsent(() => handleRakutenElement(element))) {
    rewriteAffiliatesDOM(element);
  }
};

const eventAttached = new Set();

export const handleObserver = debounceCollect((nodes, firstRun = false) => {
  debug('handleObserver', nodes, firstRun);

  const elements = queryNodesBySelector(nodes, firstRun ? SELECTOR : OBSERVER_SELECTOR);
  if (!elements.length) return;

  log('found elements', elements);
  elements.forEach(handleRakutenDOM);

  const filtered = elements.filter((el) => !eventAttached.has(el));
  if (filtered.length) {
    registerEvents(handleRakutenDOM, filtered);
    filtered.forEach((el) => eventAttached.add(el));
  }
}, DEBOUNCE_TIMEOUT, false, optimizeNodes, NETWORKS.rakuten);

export const rakutenAppendBundle = () => {
  setObserver(handleObserver, NETWORKS.rakuten);
  setAffiliateNetworkAttributeObserver(handleRakutenDOM, NETWORKS.rakuten);
  handleObserver(document, true);
};

docReady(initBundle(rakutenAppendBundle, NETWORKS.rakuten));
