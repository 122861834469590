import {
  docReady,
  getUtmVal,
} from '../utils';
import {
  hasConsent,
} from '../gdpr';
import { debugging } from '../logger';

docReady(() => {
  debugging('AFFILIATE TAGGING:', 'attribution-cookie');
  const SELECTOR = ['[data-vars-ga-product-id]'];
  document.addEventListener('click', (event) => {
    debugging('AFFILIATE TAGGING:', 'attribution-cookie', 'click event listener.');
    const element = event.target.closest(SELECTOR);
    if (!element) {
      return;
    }

    // get the value of the dom attribute found in data-vars-ga-product-id
    const contentProductId = element.getAttribute('data-vars-ga-product-id');
    const productRetailerId = element.getAttribute('data-vars-ga-product-retailer-id');
    const { hostname, pathname } = window.location;
    const utmCampaign = getUtmVal('utm_campaign');

    if (hasConsent()) {
      /**
       * if consent is met, create a cookie that contains the following:
       *   refDomain: the hostname of the current page
       *   pageUrl: the pathname of the current page
       *   contentProductId: the value of the data-vars-ga-product-id attribute
       *   utmCampaign: the value of the utm_campaign query parameter
       *
       * the cookie should expire in 30 minutes
       *
       */
      const payload = {
        pageUrl: pathname,
        productRetailerId,
        utmCampaign,
        contentProductId,
        refDomain: hostname,
      };
      debugging('AFFILIATE TAGGING:', 'attribution-cookie', 'payload', payload);
      const cookieValue = JSON.stringify(payload);
      // create a cookie affiliate_attribution=${cookieValue} with path /
      // and expires within 30 mins of time created
      document.cookie = `affiliate_attribution=${cookieValue};path=/;max-age=1800`;
    } // ignore if no consent
  });
});
