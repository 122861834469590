import { docReady, initHRST, isDisabled } from './utils';
import { isLoggingEnabled, logging } from './logger';

/*
  Debug rewriting affiliate links regarding GDPR compliance
  --------------------------------------------------------------------

  1. Turn flag ON: self-hosted.affiliate.enabled for appropriate site

  2. Update the rover site metadata so that metadata.commerce.affiliate.url value
     points to the local vader js file,
     or add param to URL ?affiliatebundle_pr=XXX with Vader's PR number

  3. Update the rover site metadata so that metadata.gdpr value = 1 or set X-GDPR header = true

  4. add URL params: ?affiliateLogging and &initGDPR (if necessary)
    also, it could be disabled for some networks: &disableAffiliateBundle=AMAZON

  HRST.commerce.affiliate.gdpr.GDPR_CONSENT_GROUPS contains current window.GDPR_CONSENT_GROUPS value
    (categories of GDPR cookies)

  Call from console: hrstGdprProxy.GDPR_CONSENT_GROUPS = '' - to emulate Decline Cookies
  Call from console: hrstGdprProxy.GDPR_CONSENT_GROUPS = 'C0003' - to emulate Accept GDPR Cookies
  It calls all affiliate scripts when GDPR_CONSENT_GROUPS is changed
*/

const initGDPRProxy = () => {
  const HRST = initHRST();
  function hrstGdprWatch(gdprConsentGroups) {
    if (HRST?.commerce?.affiliate?.gdpr === undefined) {
      HRST.commerce.affiliate.gdpr = {};
    }
    HRST.commerce.affiliate.gdpr.GDPR_CONSENT_GROUPS = gdprConsentGroups;
    return new Proxy(HRST.commerce.affiliate.gdpr, {
      set(target, property, value) {
        const gdpr = target;
        gdpr[property] = value;
        if (window.GDPR_CONSENT_GROUPS !== HRST?.commerce?.affiliate?.gdpr?.GDPR_CONSENT_GROUPS) {
          logging('AFFILIATE DEBUG:', 'GDPR is changed: ', property, value);
          window.GDPR_CONSENT_GROUPS = HRST?.commerce?.affiliate?.gdpr?.GDPR_CONSENT_GROUPS;

          // call all affiliate scripts
          let event; // The custom event that will be created
          if (document.createEvent) {
            event = document.createEvent('HTMLEvents');
            event.initEvent('cmp.consent.change', true, true);
            event.eventName = 'cmp.consent.change';
            document.dispatchEvent(event);
          }
        } else {
          logging('AFFILIATE DEBUG:', 'GDPR nothing to change...');
        }
      },
    });
  }
  if (isLoggingEnabled) {
    global.hrstGdprProxy = hrstGdprWatch(window.GDPR_CONSENT_GROUPS);
  }
};

if (!isDisabled()) {
  docReady(initGDPRProxy);
}
